.font(@font) {
	body,
	button,
	select,
	textarea,
	input,
	.btn {
		font-family: @font;
	}
}
.clearfix() {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}
.color-light(@color, @light: 5) {

	@h: hue(@color);
	@s: saturation(@color);
	@l: lightness(@color) + @light;

	color: hsl(@h,@s,@l);
}
.background-color-light(@color, @light: 5) {

	@h: hue(@color);
	@s: saturation(@color);
	@l: lightness(@color) + @light;

	background-color: hsl(@h,@s,@l);
}
.border-color-light(@color, @light: 5) {

	@h: hue(@color);
	@s: saturation(@color);
	@l: lightness(@color) + @light;

	border-color: hsl(@h,@s,@l);
}
.color-dark(@color, @dark: 5) {

	@h: hue(@color);
	@s: saturation(@color);
	@l: lightness(@color) - @dark;

	color: hsl(@h,@s,@l);
}
.background-color-dark(@color, @dark: 5) {

	@h: hue(@color);
	@s: saturation(@color);
	@l: lightness(@color) - @dark;

	background-color: hsl(@h,@s,@l);
}
.border-color-dark(@color, @dark: 5) {

	@h: hue(@color);
	@s: saturation(@color);
	@l: lightness(@color) - @dark;

	border-color: hsl(@h,@s,@l);
}

.box-sizing(@box-model) {
	-webkit-box-sizing: @box-model;
	-moz-box-sizing: @box-model;
	box-sizing: @box-model;
}
.vertical-align() {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	-ms-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	-ms-justify-content: center;
	justify-content: center;
}
.gradient-vertical(@start: #555, @end: #333) {
	background-color: mix(@start, @end, 60%);
	background-image: -moz-linear-gradient(top, @start, @end); // FF 3.6+
	background-image: -ms-linear-gradient(top, @start, @end); // IE10
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(@start), to(@end)); // Safari 4+, Chrome 2+
	background-image: -webkit-linear-gradient(top, @start, @end); // Safari 5.1+, Chrome 10+
	background-image: -o-linear-gradient(top, @start, @end); // Opera 11.10
	background-image: linear-gradient(top, @start, @end); // The standard
}
.gradient-horizontal(@color1: #555, @color2: #333) {
    background: @color1;
    background: -moz-linear-gradient(left,  @color1 0%, @color2 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,@color1), color-stop(100%,@color2));
    background: -webkit-linear-gradient(left,  @color1 0%,@color2 100%);
    background: -o-linear-gradient(left,  @color1 0%,@color2 100%);
    background: -ms-linear-gradient(left,  @color1 0%,@color2 100%);
    background: linear-gradient(left,  @color1 0%,@color2 100%);
}
.gradient-vertical-to-opacity(@color, @start-opacity: 1) {

	@rgba-start: rgba(red(@color), green(@color), blue(@color), 0);
	@rgba-end: rgba(red(@color), green(@color), blue(@color), @start-opacity);

	background: -moz-linear-gradient(top, @rgba-start 0%, @rgba-end 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,@rgba-start), color-stop(100%,@rgba-end));
	background: -webkit-linear-gradient(top,  @rgba-start 0%, @rgba-end 100%);
	background: -o-linear-gradient(top,  @rgba-start 0%, @rgba-end 100%);
	background: -ms-linear-gradient(top,  @rgba-start 0%, @rgba-end 100%);
	background: linear-gradient(to bottom,  @rgba-start 0%, @rgba-end 100%);
}
.gradient-horizontal-to-opacity(@color, @start-opacity: 1) {

	@rgba-start: rgba(red(@color), green(@color), blue(@color), @start-opacity);
	@rgba-end: rgba(red(@color), green(@color), blue(@color), 0);

	background: -moz-linear-gradient(left,  @rgba-start 0%, @rgba-end 100%);
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,@rgba-start), color-stop(100%,@rgba-end));
	background: -webkit-linear-gradient(left,  @rgba-start 0%,@rgba-end 100%);
	background: -o-linear-gradient(left,  @rgba-start 0%,@rgba-end 100%);
	background: -ms-linear-gradient(left,  @rgba-start 0%,@rgba-end 100%);
	background: linear-gradient(to right,  @rgba-start 0%,@rgba-end 100%);


}
.border-top-radius(@radius) {
	border-top-right-radius: @radius;
	border-top-left-radius: @radius;
}
.border-right-radius(@radius) {
	border-bottom-right-radius: @radius;
	border-top-right-radius: @radius;
}
.border-bottom-radius(@radius) {
	border-bottom-right-radius: @radius;
	border-bottom-left-radius: @radius;
}
.border-left-radius(@radius) {
	border-bottom-left-radius: @radius;
	border-top-left-radius: @radius;
}
.retina-image(@path, @w: auto, @h: auto) {
  background-image: url(@path);
  @at2x_path: ~`@{path}.replace(/\.\w+$/, function(match) { return "@2x" + match; })`;

  @media
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (   min--moz-device-pixel-ratio: 2),
  only screen and (     -o-min-device-pixel-ratio: 2/1),
  only screen and (        min-device-pixel-ratio: 2),
  only screen and (                min-resolution: 192dpi),
  only screen and (                min-resolution: 2dppx) {
    background-image: url(@at2x_path);
    background-size: @w @h;
  }
}
.transition(@transition: all linear .2s) {
	-moz-transition: @transition;
	transition: @transition;
}
.blur(@radius) {
	-webkit-filter: blur(@radius);
	-moz-filter: blur(@radius);
	-ms-filter: blur(@radius);
	filter: blur(@radius);
}
.rotate(@deg) {
    -webkit-transform: rotate(@deg);
    -moz-transform: rotate(@deg);
    -ms-transform: rotate(@deg);
    -o-transform: rotate(@deg);
    transform: rotate(@deg);
}
.arrow-top(@color: #fff, @size: 5px, @left: 5px) {
	&:after {
		bottom: 100%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-bottom-color: @color;
		border-width: @size;
		left: @left;
	}
}
.arrow-bottom(@color: #fff, @size: 5px, @left: 5px) {
	&:after {
		top: 100%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-top-color: @color;
		border-width: @size;
		left: @left;
	}
}
.arrow-left(@color: #fff, @size: 5px, @top: 5px) {
	&:after {
		right: 100%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-right-color: @color;
		border-width: @size;
		top: @top;
	}
}
.arrow-right(@color: #fff, @size: 5px, @top: 5px) {
	&:after {
		left: 100%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-left-color: @color;
		border-width: @size;
		top: @top;
	}
}
.close(@r: 0, @g: 0, @b: 0) {
	padding: 4px 6px;
	line-height: 1;
	font-size: 20px;
	cursor: pointer;
	color: rgba(@r, @g, @b, 1);
	text-decoration: none;
	opacity: 0.5;
	border: none;
	&:before {
		content: '\00D7';
	}
	&:hover {
		opacity: 1;
	}
}
.striped(@color: rgba(255, 255, 255, .2); @angle: 45deg) {
	background-image: -webkit-linear-gradient(@angle, @color 25%, transparent 25%, transparent 50%, @color 50%, @color 75%, transparent 75%, transparent);
	background-image: -o-linear-gradient(@angle, @color 25%, transparent 25%, transparent 50%, @color 50%, @color 75%, transparent 75%, transparent);
	background-image: linear-gradient(@angle, @color 25%, transparent 25%, transparent 50%, @color 50%, @color 75%, transparent 75%, transparent);
}
.animation(@animation) {
	-webkit-animation: @animation;
	-o-animation: @animation;
	animation: @animation;
}

// Grid
.row() {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-flex-basis: 100%;
	-ms-flex-basis: 100%;
	flex-basis: 100%;

	@media (max-width: @breakpoint-small) {
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;

	}
}
.offset(@num) {
	margin-left: calc((100%/@grid-columns*@num) + ((@grid-gutter/10)+0em)/2);
}
.col(@num) {
	width: (100%/@grid-columns*@num) * 1%;
}
.col-fixed(@width) {
	-webkit-flex: 0 0 @width;
	-ms-flex: 0 0 @width;
	flex: 0 0 @width;
	@media (max-width: @breakpoint-small) {
		-webkit-flex: 0;
		-ms-flex: 0;
		flex: 0;
		width: 100%;
	}
}
.media-grid(@num) {
	-webkit-column-count: @num;
	-moz-column-count: @num;
	column-count: @num;
	-webkit-column-gap: @grid-gutter;
	-moz-column-gap: @grid-gutter;
	column-gap: @grid-gutter;

	& > div {
		display: inline-block;
		width: 100%;
	}

	@media (max-width: @breakpoint-small) {
		-webkit-column-count: 1;
		-moz-column-count: 1;
		column-count: 1;
	}
}

// Label
.make-label(@back, @text) {
	background: @back;
	color: @text;
	&[outline] {
		background: none;
		color: @back;
		border-color: @back;
	}
	& a {
		color: @text;
		text-decoration: none;
		&:hover {
			.transition;
			color: rgba(red(@text), green(@text), blue(@text), .6);
		}
	}
}

// Btn
.make-btn(@main-color, @hover-light: 5) {

	color: rgba(255, 255, 255, .9);
	background: @main-color;

	&:hover {
		color: rgba(255, 255, 255, .7);
		.background-color-light(@main-color, @hover-light);
	}
	&[disabled] {

		&:hover {
			color: rgba(255, 255, 255, .9);
			background: @main-color;
		}
	}
	&[outline] {
		background: none;
		color: @main-color;
		border-color: @main-color;

		&:hover {
			background: @main-color;
			border-color: @main-color;
			color: rgba(255, 255, 255, .9);
		}
		&[disabled] {

			&:hover {
				background: none;
				border-color: @main-color;
				color: @main-color;
			}
		}
	}

}